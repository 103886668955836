<template>
  <div>
    <HomeOffersGroupSlider
      :query="{
        'store_ids[]': [store?.id],
        limit: 1000
      }"
    />

    <MaterialsGrid
      class="e_store-container min-h-screen mb-5"
      :fetch-route="domainRoutes.materialsByServer"
      :fetch-params="{
        offersOnly: true,
        store_id: store?.id
      }"
    />
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'

const { url } = useDomainHost()
const { store } = useDomainState()
const { t } = useI18n()
const { isAuthLoggedIn } = useMenuModel()
const domainRoutes = getAllRoutes(isAuthLoggedIn()).materialsRoutes.domainRoutes
useServerSeoMeta(
  useOgMeta(
    url + '/offers',
    t('offers_discounts'),
    store.value?.about,
    store.value?.thumb
  )
)

useHead({
  title: t('offers_discounts'),
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/offers')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, store.value?.name, url),
      useSeoBreadcrumbItem(2, t('offers_discounts'), url + '/offers')
    ])
  ]
})
</script>
